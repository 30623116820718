import blue from './blue'
import purple from './purple'
import wechat from './wechat'
import vue from './vue'

export default {
  blue,
  vue,
  wechat,
  purple
}
